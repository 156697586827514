import { Functions, httpsCallable } from "firebase/functions";

const getExternalFunctions = (functions: Functions) => {
    return {
        analyzeImage: httpsCallable(functions, "analyzeImage"),
        getPrompt: httpsCallable(functions, "getPrompt"),
        saveToGoogleSheet: httpsCallable(functions, "saveToGoogleSheet"),
    };
};

export default getExternalFunctions;
