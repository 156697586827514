import { useContext, useState } from "react";
import "./App.css";
import { FirebaseContext } from "./context/FirebaseContext";
import { GoogleAuthProvider, signInWithRedirect, getRedirectResult, UserCredential } from "firebase/auth";
import { Spin, Button, Layout } from "antd";
import Workspace from "./components/Workspace";

const { Header, Content, Footer } = Layout;

function App() {
    const [isAuth, setIsAuth] = useState(false);

    const firebase = useContext(FirebaseContext);

    const auth = firebase.auth;

    auth.onAuthStateChanged((user) => {
        if (user) {
            setIsAuth(true);
        } else {
            setIsAuth(false);
        }
    });

    getRedirectResult(auth)
        .then((result) => {
            if (!result?.user && !auth.currentUser) {
                const provider = new GoogleAuthProvider();
                provider.addScope("https://www.googleapis.com/auth/spreadsheets");
                provider.addScope("https://www.googleapis.com/auth/drive.file");

                signInWithRedirect(auth, provider);
            } else {
                if (result) {
                    const credential = GoogleAuthProvider.credentialFromResult(result);
                    if (credential) {
                        const token = credential.accessToken || "";

                        localStorage.setItem("token", token);
                    }
                }
            }
        })
        .catch((error) => {
            console.error(error);
        });

    if (!isAuth) {
        return <Spin spinning={true} tip="Autenticando..." fullscreen />;
    }

    return (
        <Layout>
            <Header
                style={{
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <Button onClick={() => auth.signOut()}>Logout</Button>
            </Header>
            <Content style={{ padding: 48 }}>
                <Workspace />
            </Content>
            <Footer>Footer</Footer>
        </Layout>
    );
}

export default App;
