import { createContext } from "react";
import { FirebaseApp } from "firebase/app";
import { Auth } from "firebase/auth";
import { FirebaseStorage } from "firebase/storage";
import { HttpsCallable } from "firebase/functions";

interface IFirebaseContext {
    app: FirebaseApp;
    auth: Auth;
    storage: FirebaseStorage;
    functions: {
        analyzeImage: HttpsCallable;
        getPrompt: HttpsCallable;
        saveToGoogleSheet: HttpsCallable;
    };
}

export const FirebaseContext = createContext<IFirebaseContext>({} as IFirebaseContext);
