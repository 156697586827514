import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import getExternalFunctions from "./externalFunctions";

const firebaseConfig = {
    apiKey: "AIzaSyBmte3mWRsRHMKXAKs2h30-Xhsbvlj5Qfg",
    authDomain: "rqgenerator.analytics.attachmedia.com", //"atm-ai-rq-generator-p-202.firebaseapp.com",
    projectId: "atm-ai-rq-generator-p-202",
    storageBucket: "atm-ai-rq-generator-p-202.appspot.com",
    messagingSenderId: "212135694615",
    appId: "1:212135694615:web:670ea01ed00840f8aa6752",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
auth.languageCode = "es";
const storage = getStorage(app);
const functions = getFunctions(app);

const externalFunctions = getExternalFunctions(functions);

const firebaseApplication = {
    app,
    auth,
    storage,
    functions: externalFunctions,
};

export default firebaseApplication;
