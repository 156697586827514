import { SyntheticEvent, useContext, useState, useEffect } from "react";
import { Button, Upload, Table, Popconfirm, Image, Spin, Input, Typography } from "antd";
import { UploadOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { ref, uploadBytes, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { GoogleAuthProvider } from "firebase/auth";

import SyntaxHighlighter from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";

import { AnalyzeImageEvent, AnalyzeImageResponse } from "./types";

import { FirebaseContext } from "../context/FirebaseContext";

const { TextArea } = Input;

const Workspace = () => {
    const firebase = useContext(FirebaseContext);
    const [uploadList, setUploadList] = useState([]);
    const [eventsList, setEventsList] = useState([] as AnalyzeImageEvent[]);
    const [previewImage, setPreviewImage] = useState("");
    const [loadingTable, setLoadingTable] = useState(false);
    const [loadingImage, setLoadingImage] = useState(false);
    const [prompt, setPrompt] = useState("");
    const [savingToGoogleSheet, setSavingToGoogleSheet] = useState(false);
    const [sheetUrl, setSheetUrl] = useState("");

    useEffect(() => {
        const getPrompt = async () => {
            try {
                const promptResult = await firebase.functions.getPrompt();
                setPrompt((promptResult.data as any).prompt);
            } catch (error) {
                console.error("Error getting prompt", error);
            }
        };

        getPrompt();
    }, []);

    const columns = [
        {
            title: "Evento",
            dataIndex: "event",
            key: "event",
            editable: false,
            /*onCell: (record: any) => ({
                rowSpan: record.paramsCount,
            }),*/
        },
        {
            title: "Trigger",
            dataIndex: "trigger",
            key: "trigger",
            editable: false,
            /*onCell: (record: any) => ({
                rowSpan: record.paramsCount,
            }),*/
        },
        {
            title: "Tag Script",
            dataIndex: "tag_script",
            key: "tag_script",
            editable: false,
            width: 300,
            render: (tag_script: any) => {
                return (
                    <SyntaxHighlighter language="javascript" style={a11yDark} wrapLines={true} wrapLongLines={true}>
                        {tag_script}
                    </SyntaxHighlighter>
                );
            },
            /*onCell: (record: any) => ({
                rowSpan: record.paramsCount,
            }),*/
        },
        /*{
            title: "Parámetros",
            children: [
                {
                    title: "Nombre",
                    dataIndex: "param_name",
                    key: "param_name",
                },
                {
                    title: "Valor",
                    dataIndex: "param_value",
                    key: "param_value",
                },
                {
                    title: "Ejemplo",
                    dataIndex: "example_value",
                    key: "example_value",
                },
            ],
        },*/
        {
            title: "Parámetros",
            dataIndex: "parameters",
            key: "parameters",
            render: (parameters: any) => {
                return (
                    <ul>
                        {parameters.map((param: any, index: number) => (
                            <li key={index}>
                                <strong>{param.param_name}</strong>: {param.param_value}
                            </li>
                        ))}
                    </ul>
                );
            },
        },
        {
            title: "Operación",
            dataIndex: "operation",
            render: (_: any, record: any) =>
                eventsList.length >= 1 ? (
                    <Popconfirm title="¿Eliminar seguro?" onConfirm={() => handleDelete(record.key)}>
                        <a>Eliminar</a>
                    </Popconfirm>
                ) : null,
            /*onCell: (record: any) => ({
                rowSpan: record.paramsCount,
            }),*/
        },
    ];

    const uploadImage = async (options: any) => {
        const { onSuccess, onError, file, onProgress } = options;

        const user = firebase.auth.currentUser;

        const storage = firebase.storage;

        const storageRef = ref(storage, `images/${user?.uid}/${file.name}`);

        const uploadTask = uploadBytesResumable(storageRef, file);

        setLoadingImage(true);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                onProgress({ percent: progress });
            },
            (error) => {
                console.error("Error uploading file", error);
                onError({ err: error });
            },
            () => {
                setLoadingImage(false);
                onSuccess("Ok");
            }
        );

        uploadBytes(storageRef, file)
            .then((snapshot) => {
                onSuccess("Ok");
            })
            .catch((error) => {
                console.error("Error uploading file", error);
                onError({ err: error });
            });
    };

    const handleDelete = (key: any) => {
        const newData = eventsList.filter((item: any) => item.key !== key);
        setEventsList(newData);
    };

    const handleOnChange = ({ file, fileList }: any) => {
        setUploadList(fileList);
        handlePreview(file);
    };

    const handleProcessIA = async (e: SyntheticEvent) => {
        e.preventDefault();

        setLoadingTable(true);

        const storage = firebase.storage;

        // @ts-ignore
        const storageRef = ref(storage, `images/${uploadList[0].name}`);

        const downloadURL = await getDownloadURL(storageRef);

        try {
            const imageAnalyzeResult = await firebase.functions.analyzeImage({ imageUri: downloadURL, prompt });

            let data = imageAnalyzeResult.data as AnalyzeImageResponse;

            const events = data.events.map((event, index) => {
                return {
                    ...event,
                    paramsCount: event.parameters.length,
                };
            });

            // Flatten the parameters for make unique rows in a single list with the other properties
            const flatten = events.flatMap((event) => {
                return event.parameters.map((param) => {
                    return {
                        event: event.event,
                        trigger: event.trigger,
                        tag_script: event.tag_script,
                        param_name: param.param_name,
                        param_value: param.param_value,
                        example_value: param.example_value,
                        paramsCount: event.paramsCount,
                    };
                });
            });

            setEventsList(data.events);
        } catch (error) {
            console.error("Error analyzing image", error);
        } finally {
            setLoadingTable(false);
        }
    };

    const handlePreview = async (file: any) => {
        if (!file.url && !file.preview) {
            file.preview = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        setPreviewImage(file.url || file.preview);
    };

    const onChangePrompt = (e: any) => {
        setPrompt(e.target.value);
    };

    const handleSaveGoogleSheet = async () => {
        setSavingToGoogleSheet(true);

        try {
            // Generate the OAuth2 Token
            const user = firebase.auth.currentUser;
            const idToken = await user?.getIdToken();

            const credential = GoogleAuthProvider.credential(idToken);
            //const token = credential.accessToken;

            const token = localStorage.getItem("token");

            const result = await firebase.functions.saveToGoogleSheet({ token, rows: eventsList });

            const data = result.data as any;

            const sheetUrl = `https://docs.google.com/spreadsheets/d/${data.spreadsheetId}`;

            setSheetUrl(sheetUrl);
        } catch (error) {
            console.error("Error saving to Google Sheet", error);
        } finally {
            setSavingToGoogleSheet(false);
        }
    };

    const showUploadList = {
        showDownloadIcon: true,
        downloadIcon: (
            <Spin spinning={loadingTable}>
                <PlayCircleOutlined onClick={(e) => handleProcessIA(e)} />
            </Spin>
        ),
        showRemoveIcon: true,
    };

    return (
        <>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
                    <div style={{ display: "flex", width: "70%" }}>
                        {previewImage && (
                            <Spin tip="Cargando" spinning={loadingImage}>
                                <Image wrapperStyle={{}} src={previewImage} />
                            </Spin>
                        )}
                    </div>
                    <div style={{ display: "flex", width: "30%", flexDirection: "column", gap: "20px" }}>
                        <div>
                            <Upload
                                showUploadList={showUploadList}
                                customRequest={uploadImage}
                                onChange={handleOnChange}
                                accept="image/*"
                                listType="picture"
                                fileList={uploadList}
                            >
                                <Button icon={<UploadOutlined />} disabled={uploadList.length > 0}>
                                    Cargar imagen
                                </Button>
                            </Upload>
                        </div>
                        <div>
                            <TextArea onChange={onChangePrompt} rows={6} placeholder="Prompt" value={prompt} />
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
                    <div style={{ display: "flex", flexDirection: "row", gap: 20, alignItems: "center" }}>
                        <Spin spinning={savingToGoogleSheet}>
                            <Button type="primary" disabled={!(eventsList.length > 0)} onClick={handleSaveGoogleSheet}>
                                Guardar en Google Sheets
                            </Button>
                        </Spin>

                        <Typography.Link href={sheetUrl} target="_blank" disabled={sheetUrl == ""}>
                            Ver hoja de cálculo
                        </Typography.Link>
                    </div>
                    <Table dataSource={eventsList} columns={columns} />
                </div>
            </div>
        </>
    );
};

export default Workspace;
